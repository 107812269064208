import * as React from 'react';
import UserIcon from '@material-ui/icons/Group';
import { Admin, Resource, ListGuesser, ShowGuesser, EditGuesser, defaultTheme } from 'react-admin';

import Dashboard from './pages/Dashboard';
import Login from './pages/Login'

import authProvider from './utils/authProvider';
import dataProvider from './utils/dataProvider'

import { UserList, UserEdit } from './pages/Users';
import { DefaultServicesList, DefaultServicesCreate, DefaultServicesEdit } from './pages/DefaultServices';
import { FeedList } from './pages/Feeds';


const App = () => (
    <Admin
        dataProvider={dataProvider}
        authProvider={authProvider}
        dashboard={Dashboard}
        loginPage={Login}
    >
        <Resource name="users" icon={UserIcon} list={UserList} show={ShowGuesser} edit={UserEdit}/>
        <Resource name="defaultservices" list={DefaultServicesList} show={ShowGuesser} edit={DefaultServicesEdit} create={DefaultServicesCreate} />
        <Resource name="feeds" list={FeedList} show={ShowGuesser} />
    </Admin>
);
export default App;
